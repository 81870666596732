import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";

export function channel(typeId, value = "", isMain = false) {
  return {
    text: value,
    id: typeId,
    isMain: isMain,
    isPreferred: false,
  };
}

export function mailChannel(value, isMain) {
  return channel(EMAIL, value, isMain);
}

export function smsChannel(value, isMain) {
  return channel(MOBILEPHONE, value, isMain);
}
