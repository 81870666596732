import { mailChannel, smsChannel } from "@/models/channel.js";

export function getFirstEmailIfValid(emails, errorMessages) {
  const mailValue = Array.isArray(emails) && emails[0] ? emails[0].text : null;
  const isValidateMessage =
    errorMessages && errorMessages.emails && errorMessages.emails[0];

  return mailValue && !isValidateMessage ? mailValue : null;
}

export function getFirstPhoneIfValid(phones, errorMessages) {
  const phoneValue = Array.isArray(phones) && phones[0] ? phones[0].text : null;
  const isValidateMessage =
    errorMessages && errorMessages.phones && errorMessages.phones[0];

  return phoneValue && !isValidateMessage ? phoneValue : null;
}

export function fillDefaultEmailAndPhoneIfEmpty(contact) {
  return {
    ...contact,
    emails:
      contact.emails && contact.emails.length > 0
        ? contact.emails
        : [mailChannel()],
    phones:
      contact.phones && contact.phones.length > 0
        ? contact.phones
        : [smsChannel()],
  };
}

export const ContactTypes = {
  Client: "CLIENT",
  Employee: "EMPLOYEE",
  Partner: "PARTNER",
};

export function getContactTypeColors(contactTypeCode) {
  if (contactTypeCode === ContactTypes.Client) {
    return "#4caf50";
  } else if (contactTypeCode === ContactTypes.Employee) {
    return "#2196f3";
  } else if (contactTypeCode === ContactTypes.Partner) {
    return "#f37e21";
  }

  return "";
}
