<template>
  <v-dialog v-model="isShown" :max-width="width" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-card-text>
        <v-card-title>
          {{ (header || textsGlobal.confirm).toUpperCase() }}
        </v-card-title>
        <v-container grid-list-lg>
          {{ confirmText || textsGlobal.confirmText }}
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="buttons">
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click.stop="onCanceled"
            class="cancel"
          >
            {{ textsGlobal.cancel }}
          </v-btn>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            :loading="loading"
            @click.stop="onConfirmed"
          >
            {{ textsGlobal.confirm }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalMixin from "@/mixins/modalMixin.js";

export default {
  mixins: [ModalMixin],
  name: "ConfirmModal",
  props: {
    header: {
      type: String,
    },
    width: {
      type: Number,
      default: 390,
    },
    disabled: {
      type: Boolean,
    },
    confirmText: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textsGlobal: this.$t("global"),
    };
  },
  methods: {
    onConfirmed() {
      this.$emit("confirm");
      this.isShown = false;
    },
    onCanceled() {
      this.$emit("cancel");
      this.isShown = false;
    },
    onClosed() {},
  },
};
</script>

<style scoped lang="scss">
.v-card__title {
  color: $text-title;
}
.buttons {
  margin-left: auto;
  margin-bottom: 15px;

  .cancel {
    margin-right: 10px;
  }
}
</style>
