<template>
  <div class="contact-details-wrapper">
    <v-progress-linear
      class="loader"
      indeterminate
      absolute
      v-show="isLoading"
      color="loaderColor"
    />
    <v-tabs
      v-if="initialContact"
      hide-slider
      background-color="#fafafa"
      :value="selectedViewTab"
      @change="onSelectViewTabChange"
    >
      <v-tab class="header-tab-item">
        <v-btn @click.stop="goBack" icon class="go-back-arrow">
          <IconBox
            :tooltip="$t('common.back')"
            :styles="{ 'font-size': '20px' }"
            color="iconButton"
          >
            stem-arrowBack
          </IconBox>
        </v-btn>
        <div :class="selectedViewTab === 0 ? 'header-tab-item-selected' : ''">
          {{ $t("contacts.details") }}
        </div>
      </v-tab>
      <v-tab-item>
        <ContactDetails
          ref="contactDetails"
          :contactId="contactId"
          :contact="editorContactState"
          :editMode="isEditMode"
          @onChange="onChangeValue"
          @isErrorOccured="setValidationErrorOccured"
        />
      </v-tab-item>

      <div class="header-tab-buttons">
        <!-- EDIT BUTTON -->
        <v-btn
          v-if="allowEditContact"
          v-can:CRM_WRITE
          icon
          text
          @click="isEditMode = true"
        >
          <IconBox color="iconButton" :tooltip="$t('common.edit')">
            stem-edit
          </IconBox>
        </v-btn>

        <!-- DELETE BUTTON -->
        <v-btn
          v-if="allowDeleteContact"
          v-can:CRM_WRITE
          icon
          text
          @click.stop="isConfirmDeleteModalShown = true"
        >
          <IconBox :tooltip="$t('common.delete')" color="iconButton">
            stem-delete
          </IconBox>
        </v-btn>

        <v-btn v-if="!isEditMode && !allowDeleteContact" icon text>
          <IconBox
            :tooltip="$t('contacts.unableToDeleteButtonTooltip')"
            color="disabled"
          >
            stem-delete
          </IconBox>
        </v-btn>

        <!-- EDIT ACTION BUTTONS -->
        <div v-if="isEditMode" class="edit-action-buttons">
          <v-btn color="roundButton" :disabled="isLoading" @click="onSave">
            <IconBox color="roundButtonText" :tooltip="$t('common.save')">
              stem-confirm
            </IconBox>
          </v-btn>

          <v-btn
            color="roundButton"
            :disabled="isLoading"
            @click="onCancelEditing"
          >
            <IconBox color="roundButtonText" :tooltip="$t('common.cancel')">
              fa-times
            </IconBox>
          </v-btn>
        </div>
      </div>
    </v-tabs>
    <ConfirmModal
      v-model="isConfirmDeleteModalShown"
      @confirm="deleteContact"
      @cancel="isConfirmDeleteModalShown = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";

import { EmitSuccess, EmitError } from "@/eventBus.js";
import { fillDefaultEmailAndPhoneIfEmpty } from "@/models/contactsHelper";

import CrmCustomersService from "@/services/CrmCustomersService";

import ConfirmModal from "@/components/Shared/ConfirmModal";

import ContactDetails from "@/components/Contacts/ContactDetails";

export default {
  name: "ContactDetailsWrapper",
  components: {
    ConfirmModal,
    ContactDetails,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.setContactId(to.params.id));
  },
  beforeRouteUpdate(to, from, next) {
    next((vm) => vm.setContactId(to.params.id));
  },
  data() {
    return {
      contactId: "",
      isLoading: false,
      selectedViewTab: 0,
      isEditMode: false,
      isConfirmDeleteModalShown: false,
      initialContact: null,
      editorContactState: null,
      isValidationErrorOccured: false,
    };
  },
  computed: {
    ...mapGetters("settings", ["getUserRestrictions"]),
    userRestrictions() {
      return this.getUserRestrictions ? this.getUserRestrictions.global : [];
    },
    allowEditContact() {
      return !this.isEditMode && (this.initialContact || {}).canBeEdited;
    },
    allowDeleteContact() {
      return (
        !this.isEditMode &&
        this.userRestrictions.some((item) => item.id === 402) &&
        (this.initialContact || {}).canBeDeleted
      );
    },
  },
  methods: {
    setContactId(contactId) {
      this.contactId = contactId;
    },
    onSelectViewTabChange(index) {
      this.selectedViewTab = index;
    },
    goBack() {
      this.$router.go(-1);
    },
    onCancelEditing() {
      this.isEditMode = false;
      this.editorContactState = { ...this.initialContact };
      this.selectedViewTab = 0;
    },
    onSave() {
      const isValidationError = this.$refs.contactDetails.validateBeforeSave();

      if (!isValidationError) {
        this.isLoading = true;

        CrmCustomersService.UpdateCrmCustomer(this.editorContactState)
          .then(() => {
            EmitSuccess(
              this.$t("contacts.editSuccess", {
                name: this.editorContactState.firstname,
                surname: this.editorContactState.surname,
              })
            );
            this.isEditMode = false;
            // this.$router.push({ name: "contacts" });
          })
          .catch(() => {})
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteContact() {
      this.isConfirmDeleteModalShown = false;
      this.isLoading = true;

      CrmCustomersService.deleteCrmCustomer([this.contactId])
        .then(() => {
          this.$router.push({ name: "contacts" });
        })
        .catch(() => {
          EmitError(this.$t("contacts.deleteError"));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChangeValue(propertyName, value) {
      let modifiedContact;

      if (!propertyName && value) {
        modifiedContact = {
          ...this.editorContactState,
          ...value,
        };
      } else {
        modifiedContact = {
          ...this.editorContactState,
          [propertyName]: value,
        };
      }

      this.editorContactState = modifiedContact;
    },
    setValidationErrorOccured(newValue) {
      this.isValidationErrorOccured = newValue;
    },
  },
  watch: {
    contactId(newCustomerId) {
      if (newCustomerId) {
        this.isLoading = true;

        CrmCustomersService.GetCrmCustomerDetails(newCustomerId)
          .then((response) => {
            const contact = fillDefaultEmailAndPhoneIfEmpty(response);

            this.isEditMode = false;
            this.initialContact = cloneDeep(contact);
            this.editorContactState = cloneDeep(contact);
          })
          .catch(() => {
            this.initialContact = null;
            this.editorContactState = null;
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>

<style scope lang="scss">
.contact-details-wrapper {
  margin: 20px -12px -12px -12px;

  .loader {
    margin-top: -31px;
  }

  .v-slide-group__content {
    padding-top: 2px; // to display box shadow on top
    // border-bottom: 1px solid #e0e0e0;

    .header-tab-item {
      min-width: 150px;
      border-radius: 0 20px 0 0;
      background-color: $tab-item-background;
      font-weight: bold;
      margin-right: 2px; // to display box shadow on right
      margin-bottom: -2px; // to hide box shadow on tab's bottom
      box-shadow: 1px -1px 5px -3px rgba(66, 68, 90, 1);

      &.v-tab--active {
        background-color: white;
      }

      .icon-wrapper {
        margin-left: 5px;
      }

      .go-back-arrow {
        margin-right: 10px;
      }

      .header-tab-item-selected {
        color: $text-title;
      }
    }

    .header-tab-item::before {
      border-radius: 0 20px 0 0;
    }
  }

  .header-tab-buttons {
    margin-left: auto;
    margin-right: 20px;
    align-self: center;

    .edit-action-buttons > button {
      margin-right: 10px;
      height: 32px;
      width: 64px;
      border-radius: 5px;
    }
  }
}
</style>
