import axios from "../plugins/http";
const CancelToken = axios.CancelToken;
let cityCancel;
let streetCancel;
let apiUrl = "api/teryt";

export default {
  GetCitiesAutocomplete(search) {
    let params = { search };
    if (cityCancel !== undefined) {
      cityCancel();
    }

    return axios.get(`${apiUrl}/cities`, {
      params,
      cancelToken: new CancelToken(function executor(x) {
        cityCancel = x;
      }),
    });
  },
  GetStreetsAutocomplete(search, cityId) {
    let model = { search, cityId };
    if (streetCancel !== undefined) {
      streetCancel();
    }

    return axios.get(`${apiUrl}/streets`, {
      params: model,
      cancelToken: new CancelToken(function executor(x) {
        streetCancel = x;
      }),
    });
  },
};
