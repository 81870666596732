<template>
  <div class="contact-details-container">
    <DraftGuard :work-in-progress="editMode">
      <TwoSides v-if="contact !== null">
        <template #left>
          <div>
            <!-- Imię i nazwisko -->
            <div class="content-section">
              <div class="field">
                <CategoryField
                  textField
                  :edit="editMode"
                  :label="$t('common.firstName')"
                  :value="contact.firstname"
                  :error-messages="errorMessages.firstname"
                  @input="(value) => onValueChange('firstname', value)"
                />
              </div>

              <div class="field">
                <CategoryField
                  textField
                  :edit="editMode"
                  :label="$t('common.surName')"
                  :value="contact.surname"
                  :error-messages="errorMessages.surname"
                  @input="(value) => onValueChange('surname', value)"
                />
              </div>
            </div>

            <!-- Typ -->
            <div class="content-section">
              <div class="field">
                <CategoryField
                  selectField
                  return-object
                  :edit="editMode"
                  :label="$t('global.type')"
                  :value="contact.type"
                  :options="contactTypeOptions"
                  @input="(value) => onValueChange('type', value)"
                />
              </div>
            </div>

            <!-- Data i godzina utworzenia -->
            <div class="content-section">
              <div class="field">
                <CategoryField
                  datePickerField
                  :label="$t('common.date.add')"
                  :value="displayInsertDate"
                />
              </div>

              <div class="field">
                <CategoryField
                  timePickerField
                  :label="$t('common.hour.add')"
                  :value="displayInsertDate"
                />
              </div>
            </div>

            <!-- Email i telefon -->
            <div class="content-section">
              <div class="field">
                <CustomList
                  favaritePropertyName="isPreferred"
                  :displayFavoriteButton="true"
                  :setFavoriteTooltipText="
                    $t('contacts.setAsPreferredChannelType')
                  "
                  :unsetFavoriteTooltipText="
                    $t('contacts.unsetAsPreferredChannelType')
                  "
                  :displayFavoriteButtonWhenNoEdit="true"
                  :edit="editMode"
                  :label="$t('common.email')"
                  :additionalLabel="$t('common.additionalEmail')"
                  :newItem="newMailChannel"
                  :values="contact.emails"
                  :error-messages="errorMessages.emails"
                  @input="(value) => onEmailChange(value)"
                  @setAsFavorite="
                    (index) => onSetEmailPreferredChannel(index, true)
                  "
                  @unsetAsFavorite="
                    (index) => onSetEmailPreferredChannel(index, false)
                  "
                />
              </div>

              <div class="field">
                <CustomList
                  mask="###-###-###"
                  favaritePropertyName="isPreferred"
                  :displayFavoriteButton="true"
                  :setFavoriteTooltipText="
                    $t('contacts.setAsPreferredChannelType')
                  "
                  :unsetFavoriteTooltipText="
                    $t('contacts.unsetAsPreferredChannelType')
                  "
                  :displayFavoriteButtonWhenNoEdit="true"
                  :edit="editMode"
                  :label="$t('common.phone')"
                  :additionalLabel="$t('common.additionalPhone')"
                  :newItem="newPhoneChannel"
                  :values="contact.phones"
                  :error-messages="errorMessages.phones"
                  @input="(value) => onValueChange('phones', value)"
                  @setAsFavorite="
                    (index) => onSetPhonePreferredChannel(index, true)
                  "
                  @unsetAsFavorite="
                    (index) => onSetPhonePreferredChannel(index, false)
                  "
                />
              </div>
            </div>

            <!-- Właściciel -->
            <div class="content-section">
              <div class="field">
                <CategoryField
                  autocompleteField
                  return-object
                  item-value="id"
                  :edit="editMode"
                  :options="ownerOptions"
                  :label="$t('contacts.owner')"
                  :item-text="
                    (item) => `${item.surname} ${item.name} (${item.login})`
                  "
                  :value="contact.owner"
                  @input="(value) => onValueChange('owner', value)"
                >
                  {{ contact.owner ? contact.owner.text : "" }}
                  <OwnerListItem
                    slot="item"
                    slot-scope="{ item }"
                    :item="item"
                  />
                </CategoryField>
              </div>
            </div>
          </div>
        </template>

        <template #right>
          <v-tabs slider-color="fields" background-color="transparent">
            <v-tab>{{ $t("common.details") }}</v-tab>
            <v-tab-item>
              <div class="details-content-section">
                <!-- Departament -->
                <div class="field">
                  <CategoryField
                    autocompleteField
                    return-object
                    :edit="editMode"
                    :options="fetchDepartments"
                    :label="$t('contacts.department')"
                    :value="contact.department"
                    @input="(value) => onValueChange('department', value)"
                  />
                </div>
              </div>

              <!-- Miasto i kod pocztowy -->
              <div class="address-section">
                <div class="details-content-section">
                  <div class="field">
                    <CategoryField
                      autocompleteFieldAsync
                      return-object
                      :edit="editMode"
                      :options="searchCity"
                      :label="$t('address.city')"
                      :value="contact.city"
                      @input="(value) => onValueChange('city', value)"
                    />
                  </div>

                  <div class="field">
                    <CategoryField
                      textField
                      mask="##-###"
                      :edit="editMode"
                      :label="$t('address.postCode')"
                      :value="contact.postCode"
                      @input="(value) => onValueChange('postCode', value)"
                    />
                  </div>
                </div>

                <!-- Ulica i numer budynku -->
                <div class="details-content-section">
                  <div class="field">
                    <CategoryField
                      autocompleteFieldAsync
                      return-object
                      :edit="editMode"
                      :options="searchStreet"
                      :label="$t('address.street')"
                      :value="contact.street"
                      :readonly="!contact.city || !contact.city.id"
                      @input="(value) => onValueChange('street', value)"
                    />
                  </div>
                  <div class="field">
                    <CategoryField
                      textField
                      :edit="editMode"
                      :label="$t('address.houseNo')"
                      :value="contact.houseNo"
                      @input="(value) => onValueChange('houseNo', value)"
                    />
                  </div>
                </div>
              </div>

              <!-- Opis -->
              <div class="details-content-section">
                <CategoryField
                  textAreaField
                  :edit="editMode"
                  :label="$t('contacts.description')"
                  :value="contact.description"
                  @input="(value) => onValueChange('description', value)"
                />
              </div>
            </v-tab-item>

            <v-tab>{{ $t("common.history") }}</v-tab>
            <v-tab-item>
              <HistoryList :items="contact.histories"></HistoryList>
            </v-tab-item>
          </v-tabs>
        </template>
      </TwoSides>
    </DraftGuard>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";
import { mailChannel, smsChannel } from "@/models/channel.js";

import {
  validateContactBeforeSave,
  validateEmailOrPhoneCustomList,
} from "@/mixins/validators";

import NewCrmCustomerService from "@/services/NewCrmCustomerService.js";
import CrmCustomersService from "@/services/CrmCustomersService.js";
import TerytService from "@/services/TerytService";

import TwoSides from "@/components/Shared/TwoSides";
import DraftGuard from "@/components/DraftGuard";
import HistoryList from "@/components/Shared/HistoryList";
import CategoryField from "@/components/Fields/CategoryField";
import CustomList from "@/components/Shared/CustomList";
import OwnerListItem from "@/components/ListItems/OwnerListItem";

export default {
  name: "ContactDetails",
  components: {
    DraftGuard,
    TwoSides,
    HistoryList,
    CategoryField,
    CustomList,
    OwnerListItem,
  },
  props: {
    editMode: { type: Boolean },
    contact: { type: Object },
  },
  data() {
    return {
      contactTypeOptions: [],
      ownerOptions: [],
      newMailChannel: mailChannel,
      newPhoneChannel: smsChannel,
      errorMessages: {},
      validatedCorrectedEmails: [],
      validatedCorrectedPhones: [],
      emailsToValidateIfAlreadyUse: [],
      phonesToValidateIfAlreadyUse: [],
    };
  },
  created() {
    this.fetchContactTypes();
    this.fetchOwners();

    this.validatedCorrectedEmails = this.contact.emails.map(
      (item) => item.text
    );
    this.validatedCorrectedPhones = this.contact.phones.map(
      (item) => item.text
    );
  },
  computed: {
    displayInsertDate() {
      return this.$moment(this.contact.insertDate);
    },
  },
  watch: {
    "contact.firstname"(newValue) {
      this.errorMessages.firstname =
        this.editMode && !newValue ? this.$t("rules.required") : null;
    },
    "contact.surname"(newValue) {
      this.errorMessages.surname =
        this.editMode && !newValue ? this.$t("rules.required") : null;
    },
    "contact.city"(newValue, oldValue) {
      if (
        this.editMode &&
        (!newValue || (oldValue && newValue.id !== oldValue.id))
      ) {
        this.onValueChange("street", null);
      }
    },
    "contact.emails"(newValues) {
      this.validateEmailsAfterChange(newValues);
    },
    "contact.phones"(newValues) {
      this.validatePhonesAfterChange(newValues);
    },
    emailsToValidateIfAlreadyUse(newItems) {
      if (Array.isArray(newItems) && newItems.length > 0) {
        const correctedEmails = [...this.validatedCorrectedEmails];
        const errors = this.errorMessages.emails;

        this.validateIfAlreadyUsed(newItems, EMAIL, errors, correctedEmails);
      }
    },
    phonesToValidateIfAlreadyUse(newItems) {
      if (Array.isArray(newItems) && newItems.length > 0) {
        const correctedPhones = [...this.validatedCorrectedPhones];
        const errors = this.errorMessages.phones;

        this.validateIfAlreadyUsed(
          newItems,
          MOBILEPHONE,
          errors,
          correctedPhones
        );
      }
    },
    errorMessages(newErrors) {
      let isErrorOccured;

      if (newErrors) {
        Object.keys(newErrors).forEach((errorKey) => {
          if (Array.isArray(newErrors[errorKey])) {
            isErrorOccured = newErrors[errorKey].some((item) => item);
          } else {
            if (newErrors[errorKey]) {
              isErrorOccured = true;
            }
          }
        });
      }

      this.$emit("isErrorOccured", isErrorOccured);
    },
  },
  methods: {
    fetchContactTypes() {
      NewCrmCustomerService.GetCrmTypes().then((response) => {
        this.contactTypeOptions = response.filter((type) => type.id !== 2);
      });
    },
    fetchOwners() {
      NewCrmCustomerService.GetOwners().then((response) => {
        this.ownerOptions = response;
      });
    },
    fetchDepartments() {
      return CrmCustomersService.GetDepartments(
        this.contact.department ? this.contact.department.id : null
      );
    },
    searchCity(value) {
      return TerytService.GetCitiesAutocomplete(value);
    },
    searchStreet(value) {
      return TerytService.GetStreetsAutocomplete(value, this.contact.city.id);
    },
    onValueChange(propertyName, value) {
      this.$emit("onChange", propertyName, value);
    },
    onEmailChange(value) {
      this.$emit("onChange", "emails", cloneDeep(value));
    },
    validateBeforeSave() {
      const { isValidationError, errors } = validateContactBeforeSave(
        this.contact,
        { ...this.errorMessages },
        this
      );

      this.errorMessages = errors;
      return isValidationError;
    },
    validateEmailsAfterChange(newValues) {
      if (
        (newValues && newValues[0] && newValues[0].text !== "") ||
        newValues.length > 1
      ) {
        const { errors, itemsToCheckIfAlreadyUsed } =
          validateEmailOrPhoneCustomList(newValues, EMAIL, this);

        this.errorMessages.emails = errors;

        if (itemsToCheckIfAlreadyUsed.length > 0) {
          this.emailsToValidateIfAlreadyUse = itemsToCheckIfAlreadyUsed.filter(
            (itemToCheck) =>
              !this.validatedCorrectedEmails.includes(itemToCheck.value)
          );
        }
      } else {
        this.errorMessages.emails = [];
        this.emailsToValidateIfAlreadyUse = [];
      }
    },
    validatePhonesAfterChange(newValues) {
      if (
        (newValues && newValues[0] && newValues[0].text !== "") ||
        newValues.length > 1
      ) {
        const { errors, itemsToCheckIfAlreadyUsed } =
          validateEmailOrPhoneCustomList(newValues, MOBILEPHONE, this);

        this.errorMessages.phones = errors;

        if (itemsToCheckIfAlreadyUsed.length > 0) {
          this.phonesToValidateIfAlreadyUse = itemsToCheckIfAlreadyUsed.filter(
            (itemToCheck) =>
              !this.validatedCorrectedPhones.includes(itemToCheck.value)
          );
        }
      } else {
        this.errorMessages.phones = [];
        this.phonesToValidateIfAlreadyUse = [];
      }
    },
    validateIfAlreadyUsed(itemsToValidate, type, errors, correctedItems) {
      const promises = [];

      itemsToValidate.forEach((item) => {
        promises.push(
          CrmCustomersService.CheckIsMediumTaken(
            item.value,
            type,
            this.contact.id
          )
        );
      });

      Promise.all(promises).then((responses) => {
        itemsToValidate.forEach((item, index) => {
          if (responses[index]) {
            errors[item.index] =
              type === EMAIL
                ? this.$t("rules.isUniqueFirstEmail")
                : this.$t("rules.isUniqueFirstPhone");
          } else {
            correctedItems.push(item.value);
          }
        });

        if (type === EMAIL) {
          this.errorMessages = {
            ...this.errorMessages,
            emails: errors,
          };
          this.validatedCorrectedEmails = correctedItems;
        } else if (type === MOBILEPHONE) {
          this.errorMessages = {
            ...this.errorMessages,
            phones: errors,
          };
          this.validatedCorrectedPhones = correctedItems;
        }
      });
    },
    onSetEmailPreferredChannel(preferredItemIndex, value) {
      if (this.editMode) {
        const emails = [];
        let phones = [];

        this.contact.emails.forEach((email, index) => {
          if (preferredItemIndex === index) {
            emails.push({ ...email, isPreferred: value });
          } else {
            // Only one item is allow to be preffered, so others must be set to false
            emails.push({ ...email, isPreferred: false });
          }
        });

        if (value) {
          phones = this.contact.phones.map((phone) => {
            return {
              ...phone,
              isPreferred: false,
            };
          });

          this.$emit("onChange", null, { emails: emails, phones: phones });
        } else {
          this.$emit("onChange", null, { emails: emails });
        }
      }
    },
    onSetPhonePreferredChannel(preferredItemIndex, value) {
      if (this.editMode) {
        const phones = [];
        let emails = [];

        this.contact.phones.forEach((phone, index) => {
          if (preferredItemIndex === index) {
            phones.push({ ...phone, isPreferred: value });
          } else {
            // Only one item is allow to be preffered, so others must be set to false
            phones.push({ ...phone, isPreferred: false });
          }
        });

        if (value) {
          emails = this.contact.emails.map((item) => {
            return {
              ...item,
              isPreferred: false,
            };
          });

          this.$emit("onChange", null, { emails: emails, phones: phones });
        } else {
          this.$emit("onChange", null, { phones: phones });
        }
      }
    },
  },
};
</script>

<style scope lang="scss">
.contact-details-container {
  margin-bottom: 30px;

  .address-section {
    background-color: $content-section-background;
  }

  .details-content-section {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    word-break: break-word;
  }

  .content-section {
    display: flex;
    flex-direction: row;
    padding: 20px;
    word-break: break-word;
  }

  .content-section,
  .details-content-section {
    .field {
      width: 50%;
      max-width: 50%;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .content-section:nth-child(even) {
    background-color: $content-section-background;
  }
}
</style>
