import axios from "../plugins/http";
import WithUser from "../utils/WithUser";
let apiUrl = "api/newCrmCustomer";

export default {
  GetGroups() {
    return axios.get(`${apiUrl}/crmGroups`);
  },
  GetChannelTypes() {
    return axios.get(`${apiUrl}/channelTypes`);
  },
  GetCrmTypes() {
    return axios.get(`${apiUrl}/crmTypes`);
  },
  GetOwners() {
    return axios.get(`${apiUrl}/owners`);
  },
  GetUsers() {
    return axios.get(`${apiUrl}/users`);
  },
  Create(contact) {
    const contactToSend = {
      ...contact,
      emails:
        contact.emails &&
        contact.emails[0] &&
        contact.emails[0].text.trim() === ""
          ? []
          : contact.emails,
      phones:
        contact.phones &&
        contact.phones[0] &&
        contact.phones[0].text.trim() === ""
          ? []
          : contact.phones,
    };

    var modelToSend = WithUser(contactToSend);
    return axios.post(apiUrl, modelToSend);
  },
  CreateForComplaint(contact) {
    const contactToSend = {
      ...contact,
      emails:
        contact.emails &&
        contact.emails[0] &&
        contact.emails[0].text.trim() === ""
          ? []
          : contact.emails,
      phones:
        contact.phones &&
        contact.phones[0] &&
        contact.phones[0].text.trim() === ""
          ? []
          : contact.phones,
    };

    var modelToSend = WithUser(contactToSend);
    return axios.post(`${apiUrl}/forComplaint`, modelToSend);
  },
};
